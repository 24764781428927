/* LoadingIndicator.css */

.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    /* Light grey */
    border-top: 8px solid #333;
    /* Dark grey */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}